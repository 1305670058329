<template>
  <div class="container container-padding mt-5 mt-md-0 equipment-container">
    <page-block
      v-if="!mobile"
      background-class="clear"
      title="Аппарат - “конструктор” 3 дюйма"
      subtitle="100% готов к работе сразу после покупки. Только стандартизированные комплектующие."
      align="center"
    >
      <div
        class="equipment-image d-none d-md-block"
        :style="{
          'background-image':
            'url(' + webp(require('../assets/equipment.png')) + ')',
        }"
      ></div>
      <div class="equipment-image-mobile d-md-none">
        <img
          v-lazy="{
            src: webp(require('../assets/equipment-mobile.png')),
          }"
          alt="Комплектация"
        />
      </div>
    </page-block>

    <paragraph
      v-if="mobile"
      title="Комплектация"
      subtitle="100% готов к работе сразу после покупки. Ничего докупать не надо"
    >
      <div>
        <div
          class="equipment-image d-none d-md-block"
          :style="{
            'background-image':
              'url(' + webp(require('../assets/equipment.png')) + ')',
          }"
        ></div>
        <div class="equipment-image-mobile d-md-none">
          <img
            v-lazy="{
              src: webp(require('../assets/equipment-mobile.png')),
            }"
            alt="Комплектация"
          />
        </div>
      </div>
    </paragraph>
  </div>
</template>

<script>
import PageBlock from "@/components/PageBlock";
import Paragraph from "@/pages/Main/ui/Paragraph";
import { webp } from "@/plugins/webp";
export default {
  name: "EquipmentList",
  components: { Paragraph, PageBlock },
  data() {
    return {
      mobile: false,
    };
  },
  methods: {
    webp,
  },
  mounted() {
    if (window.innerWidth < 768) this.mobile = true;
  },
};
</script>

<style lang="scss" scoped>
.equipment-container {
  @media (min-width: 768px) {
    padding: 70px 0;
  }
}
.equipment-image {
  margin-top: 27px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100vh;
  width: 120%;
}
.equipment-image-mobile {
  margin-top: 20px;
  margin-bottom: 50px;
  //background-image: url("../assets/equipment-mobile.png");
  //background-repeat: no-repeat;
  //background-position: center;
  //background-size: contain;
  //height: 100vh;
  //width: 100%;
  img {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: block;
  }
}
</style>
